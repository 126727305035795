<template>
  <div class="customer-model">
    <div class="customer-model-content">
      <div class="customer-model-content-img">
        <img src="https://bsj-card.oss-cn-shenzhen.aliyuncs.com/card/2024-06-29/667f8903e4b04234a76aab85.png" class="code" />
        <img src="https://bsj-card.oss-cn-shenzhen.aliyuncs.com/card/2024-06-29/667f8a4786bed39c35a36982.png" class="hide" />
        <p>请扫码添加客服</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'CustomerService',
  data(){
   return {

   } 
  }
}
</script>
<style>
.customer-model{
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.2);
  height: 100vh;
  width: 100vw;
}
.customer-model .customer-model-content{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customer-model .customer-model-content-img{
  background: #FFF;
  border-radius: .625rem;
  position: relative;
  width: 80%;
  padding: 1.25rem;
  text-align: center;
}
.customer-model .code{
  width: 80%;
}
.customer-model .hide{
  position: absolute;
  top: .625rem;
  right: .625rem;
  width: 1.25rem;
}
.customer-model .customer-model-content-img>p{
  text-align: center;
  color: #999;
  font-size: .8125rem;
}
</style>
import Vue from 'vue';
import App from './app.vue';
import router from './router';
import { Picker,Popup,Field } from 'vant';

Vue.config.productionTip = false;
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Field);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app');

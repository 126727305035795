<template>
  <div class="flex-col page">
    <div class="flex-col">
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          :title="title"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="onCancel"
        />
      </van-popup>
      <div class="flex-col group_2">
        <div class="flex-col section_1">
          <div class="flex-col group_3">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223924553039.png"
              class="image"
            />
            <div class="flex-col items-center group_4">
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223924421930.png"
                class="image_1"
              />
              <img
                src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223929522902.png"
                class="image_2"
              />
            </div>
          </div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223920852317.png"
            class="image_3"
          />
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223926339158.png"
            class="image_4"
          />
        </div>
        <div class="flex-col items-start group_5">
          <div class="flex-col items-start section_2">
            <div class="section_3"></div>
          </div>
          <div class="flex-col items-start text-wrapper">
            <span class="text_2">资料填写</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-col group_6">
      <span class="text_3">意向竞争性采购产品类别</span>
      <div class="justify-between equal-division">
        <div class="flex-row" @click="handleSelect(true)">
          <span>{{ electron }}</span>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223922493168.png"
            class="image_5"
          />
        </div>
      </div>
      <img
        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223927291300.png"
        class="image_8"
      />
      <div class="group_7 justify-between items-center view">
        <span class="text_6 text_7">供应商名称(全称）</span>
        <input
          class="text_8"
          placeholder="供应商名称"
          @change="$set(fromParams, 'supplier', $event.target.value)"
          :v-model="fromParams.supplier"
        />
      </div>
      <div class="group_7 justify-between items-center view_1">
        <span class="text_6">注册资本额(万元）</span>
        <input
          class="text_8"
          placeholder="注册资本额"
          @change="$set(fromParams, 'regstrPrice', $event.target.value)"
          :v-model="fromParams.regstrPrice"
        />
      </div>
      <div class="group_7 justify-between items-center view_2">
        <span class="text_6">年销售额(万元)</span>
        <input
          class="text_8"
          placeholder="年销售额"
          @change="$set(fromParams, 'yearPrice', $event.target.value)"
          :v-model="fromParams.yearPrice"
        />
      </div>
      <div class="group_7 justify-between items-center view_3">
        <span class="text_6 text_13">主要客户</span>
        <input
          class="text_8"
          placeholder="主要客户"
          @change="$set(fromParams, 'custom', $event.target.value)"
          :v-model="fromParams.custom"
        />
      </div>
      <div class="group_7 justify-between items-center view_4">
        <span class="text_6">员工人数(人）</span>
        <input
          class="text_8"
          placeholder="员工人数"
          @change="$set(fromParams, 'staffNum', $event.target.value)"
          :v-model="fromParams.staffNum"
        />
      </div>
      <div class="justify-between items-center group_8">
        <span class="text_17">月产能(Kpcs)</span>
        <input
          class="text_8"
          placeholder="月产能"
          @change="$set(fromParams, 'production', $event.target.value)"
          :v-model="fromParams.production"
        />
      </div>
      <div class="group_7 justify-between items-center view_5">
        <span class="text_6">联系人</span>
        <input
          class="text_8"
          placeholder="联系人"
          @change="$set(fromParams, 'contacts', $event.target.value)"
          :v-model="fromParams.contacts"
        />
      </div>
      <!-- <div class="justify-between items-center group_9">
        <span class="text_21">姓名</span>
        <input class="text_8" placeholder="姓名" @change="$set(fromParams,'name',$event.target.value)" :v-model="fromParams.name"/>
      </div> -->
      <div class="group_7 justify-between items-center view_6">
        <span class="text_6">电话</span>
        <input
          class="text_8"
          placeholder="电话"
          @change="$set(fromParams, 'phone', $event.target.value)"
          :v-model="fromParams.phone"
        />
      </div>
      <div class="justify-between items-center group_10">
        <span class="text_25">邮箱</span>
        <input
          class="text_8"
          placeholder="邮箱"
          @change="$set(fromParams, 'email', $event.target.value)"
          :v-model="fromParams.email"
        />
      </div>
      <div class="justify-between group_10 items-center flex-wrap">
        <span class="text_25">携客云企业号</span>
        <input
          class="text_8"
          placeholder="携客云企业号"
          @change="$set(fromParams, 'xkyUser', $event.target.value)"
          :v-model="fromParams.xkyUser"
        />
        <span @click="onJump" class="str">未注册，请<b>点击</b>注册<br /><span>注:必须为企业账户，个人账户无效</span></span>
        

      </div>
      <div @click="submitFrom" class="flex-col items-center button">
        <span>确认提交</span>
      </div>
      <!-- <img class="customer-service" src="https://bsj-card.oss-cn-shenzhen.aliyuncs.com/card/2024-06-29/667f8903e4b04234a76aab85.png" /> -->
    </div>
    <!-- <CustomerService /> -->
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import axios from "axios";
import CustomerService from "../components/CustomerService.vue";

export default {
  components:{
    CustomerService
  },
  data() {
    return {
      fromParams: {
        supplier: "",
        regstrPrice: "",
        yearPrice: "",
        custom: "",
        staffNum: "",
        production: "",
        contacts: "",
        name: "",
        phone: "",
        email: "",
        xkyUser:""
      },
      columns: [
        {
          text: "电子组",
          children: [
            { text:'硬板PCB /单面FPC软板' },
            { text:'按键开关' },
            { text:'电阻 电容' },
            { text:'连接器/卡座' },
            { text:'电感' },
            { text:'晶振' },
            { text:'Pogopin顶针' },
            { text:'发光二极管' },
            { text:'插座' },
            { text:'电解电容' },
            { text:'二三级管/TVS/ESD' },
            { text:'MOS管' },
            { text:'摄像头' },
            { text:'保险丝' },
            { text:'双工/滤波器' },
            { text:'LDO/充电/功放' },
            { text:'插头类' },
            { text:'光敏电阻' },
            { text:'外置天线' },
            { text:'SPI NOR Flash' },
            { text:'摄像头模组' },
          ],
        },
        {
          text: "结构组",
          children: [
            { text:'喇叭 咪头' },
            { text:'彩盒 纸箱 说明书' },
            { text:'码牌 镜片' },
            { text:'电池' },
            { text:'硅胶' },
            { text:'磁铁' },
            { text:'屏(断码+液晶）' },
            { text:'外壳（五金+塑胶）' },
            { text:'模切辅料' },
            { text:'屏蔽罩/弹片' },
            { text:'线材' },
            { text:'蜂鸣器' },
            { text:'打印机芯' },
            { text:'镜头' },
            { text:'电池PACK' },
            { text:'锡膏 锡条 锡线' },
            { text:'电子锁头' },
            { text:'小五金' },
            { text:'载带/吸塑' },
            { text:'危化品' },
            { text:'电源适配器' },
            { text:'振动马达' },
            { text:'IC卡' },
            { text:'继电器' },
            { text:'生产治具' },
          ],  
        },
      ],
      rows: [],
      // columns2: ,
      showPicker: false,
      title: "",
      flag: true,
      electron: "选择产品类别",
      structure: "",
    };
  },
  methods: {
    onJump(){
      window.location.href = 'https://4hkaekfqvsqy.cp.xiekeyun.com';
    },
    onConfirm(value, index) {
      this.electron = value[1];
      this.structure = value[0];
      this.showPicker = false;
    },

    onCancel() {
      this.showPicker = false;
      // Toast('取消');
    },
    async submitFrom() {
      if (this.electron === "选择产品类别") return Toast("请选择产品类别");
      this.fromParams.electron = this.electron;
      this.fromParams.structure = this.structure;
      if(!this.fromParams.xkyUser) return Toast("请输入携客云账号");

      let { data } = await axios.post(
        "http://120.77.147.168:9000/save",
        this.fromParams
      );

      if (data.flag) {
        this.fromParams = {
          supplier: "",
          regstrPrice: "",
          yearPrice: "",
          custom: "",
          staffNum: "",
          production: "",
          contacts: "",
          name: "",
          phone: "",
          email: "",
          xkyUser:"xkyUser"
        };
        Dialog.alert({
          message:
            "资料已提交,相关工作人员将在截止报名时间后联系您,期待与您的合作",
          theme: "round-button",
        }).then(() => {
          this.$router.push({ name: 'huanyingyemian' });
        });
      } else {
        Dialog.alert({
          message: "请完善资料",
          theme: "round-button",
        });
      }
    },

    handleSelect() {
      this.showPicker = true;
    },
  },
};
</script>

<style scoped lang="css">
input {
  background: none;
  border: none;
}
.group_7 {
  padding-left: 0.063rem;
}
.image_5 {
  margin: 0.22rem 0 0.16rem;
  width: 0.56rem;
  height: 0.38rem;
}
.text_6 {
  color: rgb(24, 86, 253);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.page {
  padding: 0.78rem 0 3.06rem;
  background-color: rgb(222, 229, 239);
  width: 100%;
  overflow-y: auto;
  height: 100%;
}
.group_6 {
  margin-top: 1.31rem;
  padding-left: 1.63rem;
  padding-right: 1.22rem;
}
.group_1 {
  color: rgb(46, 103, 255);
  font-size: 0.41rem;
  line-height: 0.31rem;
  letter-spacing: 0.24rem;
  white-space: nowrap;
}
.group_2 {
  margin-top: 0.16rem;
  color: rgb(24, 86, 253);
  font-size: 0.94rem;
  line-height: 0.91rem;
  white-space: nowrap;
  height: 16.13rem;
}
.text_3 {
  color: rgb(24, 86, 253);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.equal-division {
  margin-top: 1.59rem;
  color: rgb(57, 71, 109);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
  width: 10.5rem;
}
.image_8 {
  margin: 1.84rem 0.56rem 0 0.094rem;
  width: 19.94rem;
  height: 0.063rem;
}
.view {
  margin-top: 1.97rem;
}
.view_1 {
  margin-top: 1.75rem;
}
.view_2 {
  margin-top: 1.75rem;
}
.view_3 {
  margin-top: 1.72rem;
}
.view_4 {
  margin-top: 1.88rem;
}
.group_8 {
  margin-top: 1.78rem;
}
.view_5 {
  margin-top: 1.78rem;
}
.group_9 {
  margin-top: 1.91rem;
}
.view_6 {
  margin-top: 1.75rem;
}
.group_10 {
  margin-top: 1.72rem;
  padding-left: 0.094rem;
}
.button {
  margin-right: 0.44rem;
  margin-top: 2.28rem;
  padding: 0.88rem 0 0.84rem;
  color: rgb(220, 253, 255);
  font-size: 0.88rem;
  line-height: 0.81rem;
  white-space: nowrap;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487944223866689768.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.text {
  margin-bottom: 0.13rem;
}
.text_1 {
  margin-left: 0.69rem;
}
.section_1 {
  padding: 0.094rem 1.75rem 2.31rem 1.91rem;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16487934173957726055.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.group_5 {
  margin-top: -0.44rem;
  position: relative;
}
.text_7 {
  margin-bottom: 0.094rem;
}
.text_8 {
  margin-top: 0.094rem;
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_10 {
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.text_12 {
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.text_13 {
  margin-top: 0.094rem;
}
.text_14 {
  margin-bottom: 0.094rem;
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_16 {
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.text_17 {
  color: rgb(24, 86, 253);
  font-size: 0.81rem;
  line-height: 0.88rem;
  white-space: nowrap;
}
.text_18 {
  margin-top: 0.094rem;
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.75rem;
  white-space: nowrap;
}
.text_20 {
  color: rgb(100, 113, 150);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_21 {
  color: rgb(24, 86, 253);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_22 {
  margin-right: 0.094rem;
  color: rgb(112, 122, 149);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_24 {
  color: rgb(112, 122, 149);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_25 {
  color: rgb(24, 86, 253);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.text_26 {
  color: rgb(112, 122, 149);
  font-size: 0.81rem;
  line-height: 0.78rem;
  white-space: nowrap;
}
.group_3 {
  padding-left: 0.25rem;
}
.image_3 {
  margin-top: 2.84rem;
  width: 1.53rem;
  height: 1.5rem;
}
.image_4 {
  margin-right: 1.81rem;
  margin-top: 2.5rem;
  align-self: flex-end;
  width: 0.47rem;
  height: 0.47rem;
}
.section_2 {
  padding: 0.28rem 0 0.19rem;
  background-image: linear-gradient(
    90deg,
    rgb(152, 187, 255) 0%,
    rgba(152, 187, 255, 0) 100%
  );
  width: 8rem;
}
.text-wrapper {
  padding: 0.59rem 0 0.66rem;
  background-image: linear-gradient(
    90deg,
    rgb(152, 187, 255) 0%,
    rgba(152, 187, 255, 0) 100%
  );
  width: 21.19rem;
  position: absolute;
  right: -0.031rem;
  bottom: 0;
}
.image {
  align-self: flex-end;
  width: 0.56rem;
  height: 0.56rem;
}
.group_4 {
  margin-top: 0.25rem;
  padding: 0.75rem 0 0.56rem;
  position: relative;
}
.section_3 {
  margin-left: 1.44rem;
  background-image: linear-gradient(
    -85deg,
    rgb(152, 187, 255) 0%,
    rgb(41, 95, 255) 100%
  );
  width: 0.44rem;
  height: 1.66rem;
}
.text_2 {
  margin-left: 0.53rem;
}
.image_1 {
  width: 19.22rem;
  height: 2.59rem;
}
.image_2 {
  width: 5.53rem;
  height: 3.91rem;
  position: absolute;
  left: 0;
  bottom: 0;
}
.flex-wrap{
  flex-wrap: wrap;
}
.str{
  font-size: 0.72rem;
  color: #999;
  display: block;
  margin-top: 0.20rem;
}
.str>b{
  color: rgb(41, 95, 255);
  font-size: .900rem;
  font-weight: bold;
}
.customer-service{
  position: fixed;
  right: 0;
  bottom: 20%;
  width: 60px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  padding: 10px;
}
</style>
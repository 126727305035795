<template>
  <div class="flex-col page">
    <div class="flex-col section_1">
      <div class="flex-col section_2">
        <div class="group">
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250548769632.png"
            class="image"
          />
          <div class="flex-col items-center image-wrapper">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250544233298.png"
              class="image_1"
            />
          </div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250543002718.png"
            class="image_2"
          />
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250544781200.png"
            class="image_3"
          />
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250544490581.png"
            class="image_4"
          />
        </div>
        <img
          src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250542740925.png"
          class="image_5"
        />
      </div>
    </div>
    <div class="flex-col group_1">
      <div class="flex-col button">
        <span class="text">请仔细阅读以下内容</span>
      </div>
      <div class="flex-col text-wrapper">
        <div 
          class="text_1"
          v-html="content"
          @click="handleClick"
          ></div
        >
      </div>
    </div>
    <div @click="view_2OnClick" class="flex-col items-center button_1">
      <span>已了解以上内容,进入详情填写页面</span>
    </div>
    <div v-if="show" class="imagesShow">
      <div>
        <span @click="show = false">关闭</span>
        <img :src="imgUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
  export default {
    data() {
      return {
        content : '',
        imgUrl:'',
        show:false
      };
    },
    async created () {
      let {data} = await axios.get('http://120.77.147.168:9000/homeinfo/get')

      this.content = data.content;
    },
    methods: {
      view_2OnClick() {
        this.$router.push({ name: 'index' });
      },
      handleClick(e) {
        e.target.nodeName == 'IMG' && (
          this.show = true,
         this.imgUrl = e.target.src
        )
      },
    },
  };
</script>
<style>
html,body{
  width: 100%;
}
 .text_1 img{
    max-width: 100% !important;
    height: auto;
  }
</style>
<style scoped lang="css">
  .page {
    padding-bottom: 2.38rem;
    background-color: rgb(221, 229, 239);
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .imagesShow div img{
    margin: 60px auto;
    width: 90%;
  }
  .imagesShow div span{
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px;
    background: #409eff;
    border-radius: 4px;
    color: #fff;
    font-size: 3.4vw;
    padding: 6px;
    z-index: 100;
  }
  .imagesShow div{
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;
  }
  .imagesShow{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
  }
  .section_1 {
    margin-top: -4.38rem;
    padding-bottom: 0.47rem;
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250355513420.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
  }
  .group_1 {
    padding: 1.34rem 1.16rem 2.16rem;
    box-sizing: border-box;
  }
  .button_1 {
    margin: 0 1.19rem;
    padding: 0.81rem 0;
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 0.91rem;
    white-space: nowrap;
    background-image: linear-gradient(0deg, rgb(59, 114, 253) 0%, rgb(32, 91, 253) 100%);
  }
  .section_2 {
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250535372658.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .button {
    margin-left: 0.19rem;
    padding: 0.41rem 0 0.44rem;
    align-self: flex-start;
    color: rgb(255, 255, 255);
    font-size: 0.88rem;
    line-height: 0.84rem;
    white-space: nowrap;
    background-color: rgb(39, 97, 252);
    border-radius: 0.81rem;
  }
  .text-wrapper {
    margin-top: 0.94rem;
    padding: 0.97rem 0 2.22rem;
    color: rgb(59, 71, 106);
    font-size: 0.81rem;
    line-height: 1.38rem;
    background-color: rgb(241, 244, 248);
    border-radius: 0.25rem;
  }
  .group {
    height: 19.66rem;
    position: relative;
  }
  .image_5 {
    margin-right: 3.59rem;
    align-self: flex-end;
    width: 2.06rem;
    height: 2.06rem;
  }
  .text {
    margin-left: 1.06rem;
    margin-right: 0.66rem;
  }
  .text_1 {
    display: block;
    margin-left: 1.03rem;
    margin-right: 0.91rem;
  }
  .image {
    filter: drop-shadow(0px 0.31rem 1.32rem rgba(4, 55, 89, 0.19));
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    left: 2.13rem;
    top: 7.66rem;
  }
  .image-wrapper {
    padding: 9.16rem 0 3.22rem;
    background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/60e2d3036486410011d9f019/613218d2630da20011bf2601/16489006250545321909.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 13.25rem;
    position: absolute;
    right: -2rem;
    top: 0;
  }
  .image_2 {
    filter: drop-shadow(0px 0.31rem 1.32rem rgba(4, 55, 89, 0.19));
    width: 3.22rem;
    height: 3.22rem;
    position: absolute;
    left: 2.88rem;
    bottom: 0;
  }
  .image_3 {
    width: 18.44rem;
    height: 19.63rem;
    position: absolute;
    left: 0;
    top: 0;
  }
  .image_4 {
    width: 19.09rem;
    height: 11.47rem;
    position: absolute;
    bottom: 0.53rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .image_1 {
    width: 0.66rem;
    height: 0.66rem;
  }
</style>
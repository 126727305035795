import Vue from 'vue';
import VueRouter from 'vue-router';
import Wuliaozhaobiao02 from '@/pages/wuliaozhaobiao02.vue';
import Huanyingyemian from '@/pages/huanyingyemian.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'huanyingyemian',
    component: Huanyingyemian,
  },
  {
    path: '/',
    name: 'index',
    component: Wuliaozhaobiao02,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;